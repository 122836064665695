import React from 'react'
import style from './index.module.less'
import AppBetaSVG from "../../../images/SVG/download_appicon_beta.svg"
import { XButton } from '@/components/index'
import ScrollDeskTop from './Andr-Scroll/index'
import { arspaceGlass } from '@/images/experience/index'
import { useIntl } from 'react-intl'
import getCountry from '@/utils/getCountry.js'
import comcss from "@/components/Home/index.module.less"
// import useGa from '@/hooks/useGa'
import {useGTM} from '@/hooks/useGTM.js'

const Index = () => {
    const intl = useIntl()
    const region = getCountry()
    // const androidDownload = () => {
    //     // useGa("AR", "AR_nebula", "Click");
    //     region == 'th' ? null : window.open("https://play.google.com/store/apps/details?id=ai.nreal.nebula.universal")
    //     useGTM('ar_click','Nebula for Android','','')

    // }
    return <div className={style.block}>
        <h2
            className={comcss.subtitle}
        >{intl.formatMessage({ id: "ar.space" })}</h2>
        <div
            className={comcss.subdesc}
            dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `ar.space.des` })}` }}
        ></div>
        {/* <div className={style.blockDownload}>
            <AppBetaSVG />
            <XButton width="210px" onClick={androidDownload} icon="andorid_download" type='secondary' theme="black">Nebula for Android</XButton>
        </div> */}
        <ScrollDeskTop />
        <img className={style.blockGlass} src={arspaceGlass} />
    </div>
}
export default Index