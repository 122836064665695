import React from 'react'
import style from './index.module.less'
import AppBetaSVG from "../../../images/SVG/download_appicon_beta.svg"
import { XButton } from '@/components/index'
import { Parallax } from '@/components/index'
import { useIntl } from 'react-intl'
import downloadfiles from "@/utils/downloadfile"
import { getNrealapp } from '@/services/request'
import comcss from "@/components/Home/index.module.less"
import { multi_wins } from '@/images/ar/index'
// import useGa from '@/hooks/useGa'
import {useGTM} from '@/hooks/useGTM.js'

const Index = () => {
    const intl = useIntl()
    // const macDownload = () => {
    //     // useGa("AR", "AR_nebula_for_mac", "Click");
        
    //     getNrealapp({ currentVersionCode: 0, currentServiceModel: 1, currentPackageName: 'ai.nreal.nebula.mac' }).then((res) => {
    //         downloadfiles(res.data.data.latestVersion.packageUrl)
    //     })

    //     useGTM('ar_click','Nebula for Mac','','')

    // }
    return <div className={style.block}>
        <h2
            className={comcss.subtitle}
            dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `ar.mutiwindows` })}` }}
        ></h2>
        <div
            className={comcss.subdesc}
        >{intl.formatMessage({ id: "ar.mutiwindows.tip" })}</div>
        {/* <div className={style.blockDownload}>
            <AppBetaSVG />
            <XButton width="180px" onClick={macDownload} icon="mac_download" type='secondary' theme="black">Nebula for Mac</XButton>
        </div> */}
        <Parallax background={multi_wins} speed={200} styles={{ width: "100%", aspectRatio:"16/9",height: "auto" }} />
    </div>
}
export default Index